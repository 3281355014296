import { makeAutoObservable } from "mobx";

import { countMinutesBetweenDates } from "../../utils/time";
import { uuidv4 } from "../../utils/uuid";
import { AuthStore } from "../auth/auth.store";
import { NotificationModel, NotificationSeverity } from "./notifications.models";

const DEFAULT_NOTIFICATION_ESCAPE = 10000;

export class NotificationsStore {
    private notifications: NotificationModel[] = [];

    constructor(public authStore: AuthStore) {
        makeAutoObservable(this);
        setInterval(() => this.clearNotes(), DEFAULT_NOTIFICATION_ESCAPE);
    }

    clearNotes() {
        for (const notification of this.notifications.filter((n) => !n.isRead && n.timeToLiveInMin !== undefined)) {
            const diff = countMinutesBetweenDates(notification.created, new Date());
            if (notification.timeToLiveInMin && diff >= notification.timeToLiveInMin) {
                notification.isRead = true;
            }
        }
    }

    addError(message: string, title?: string, timeToLiveInMin?: number) {
        const errorNote: NotificationModel = {
            id: uuidv4(),
            title: title ?? "Some Error",
            message,
            created: new Date(),
            importance: "ERROR",
            timeToLiveInMin,
        };
        this.notifications.push(errorNote);
    }

    addNote(message: string, importance: NotificationSeverity, title?: string, timeToLiveInMin?: number) {
        const note: NotificationModel = {
            id: uuidv4(),
            title,
            message,
            created: new Date(),
            importance,
            timeToLiveInMin,
        };
        this.notifications.push(note);
    }

    addInfoNote(message: string, title?: string, timeToLiveInMin?: number) {
        this.addNote(message, "INFO", title, timeToLiveInMin);
    }

    addWarnNote(message: string, title?: string, timeToLiveInMin?: number) {
        this.addNote(message, "WARNING", title, timeToLiveInMin);
    }

    markAsRead(id: string) {
        const note = this.notifications.find((n) => n.id === id);
        if (note) {
            note.isRead = true;
        }
    }

    markAsShowed(id: string) {
        const note = this.notifications.find((n) => n.id === id);
        if (note) {
            note.isShowed = true;
        }
    }

    get unreadNotifications() {
        return this.notifications.filter((note) => note.isRead === false || note.isRead === undefined);
    }

    get getLastElement(): NotificationModel | null {
        if (this.unreadNotifications.length > 0) {
            return this.unreadNotifications[this.unreadNotifications.length - 1];
        }
        return null;
    }

    // @action
    // getLastError() {
    //     if (this.errors.length > 0) {
    //         return this.errors.pop();
    //     }
    // }
}
