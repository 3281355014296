import { ConflictError } from "./errors/ConflictError";
import { NotFoundError } from "./errors/NotFoundError";
import { RightsError } from "./errors/RightsError";
import { TooLargeError } from "./errors/TooLargeError";

export enum HttpCodes {
    OK = 200,
    CREATED = 201,

    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    CONFLICT = 409,
    TOO_LARGE = 413,
    I_AM_TEAPOT = 418,

    SERVER_ERROR = 500,
}

export const oK200Interceptor = (response: any) => response;
export const error4xxInterceptor = (error: any) => {
    if (error?.response?.status === HttpCodes.UNAUTHORIZED || error?.response?.status === HttpCodes.FORBIDDEN) {
        throw new RightsError(error?.response?.status, error);
    }
    if (error?.response?.status === HttpCodes.NOT_FOUND) {
        throw new NotFoundError(error?.response?.status);
    }
    if (error?.response?.status === HttpCodes.CONFLICT) {
        throw new ConflictError(error?.response?.status);
    }
    if (error?.response?.status === HttpCodes.TOO_LARGE) {
        throw new TooLargeError(error?.response?.status);
    }

    return Promise.reject(error);
};
