import { makeAutoObservable } from "mobx";

import { getStateFromLocalStorage, setStateToLocalStorage } from "../../utils/localStorage";
import { AppState } from "./AppState";

class AppStateStore {
    public appState?: AppState;
    public isProgress = false;

    constructor(private readonly key: string, defaultAppState: AppState) {
        makeAutoObservable(this);
        this.init(key, defaultAppState);
    }

    private init(key: string, defaultAppState: AppState) {
        this.appState = getStateFromLocalStorage(key) ?? defaultAppState ?? undefined;
    }

    private save() {
        setStateToLocalStorage(this.appState, this.key);
    }

    public updateAppState(state: AppState) {
        this.appState = state;
        this.save();
    }

    public setIsProgress(isProgress: boolean) {
        this.isProgress = isProgress;
    }
}

export { AppStateStore };
