// noinspection DuplicatedCode
import { AxiosInstance, AxiosResponse } from "axios";
import { reaction, when } from "mobx";

import { baseApiPath, getBaseUrl, getMdmServiceBasePath } from "../../../environment";
import { initAxios } from "../../utils/api-helpers";
import { AuthStore } from "../auth/auth.store";
import {
    discussionHistUrl,
    eventAnalyticsApiPath,
    proposalAnalyticsApiPath,
    tagsStatsUrl,
    talkLiveHistUrl,
    talkOfflineHistUrl,
    talkRatingsUrl,
    talkRecordHistUrl,
    talksStatsUrl,
    talkStatUrl,
    talkWatchSpreadUrl,
} from "./mdm.api.routes";
import {
    EventAnalyticsApiResponse,
    EventAnalyticsModel,
    MdmDiscussionHistApiResponse,
    MdmDiscussionHistModel,
    MdmTagsStatisticsApiResponse,
    MdmTagStatisticModel,
    MdmTalkHistApiResponse,
    MdmTalkHistModel,
    MdmTalkRatingModel,
    MdmTalkRatingsApiResponse,
    MdmTalksStatisticsApiResponse,
    MdmTalkStatisticApiResponse,
    MdmTalkStatisticModel,
    MdmTalkWatchSpreadApiResponse,
    MdmTalkWatchSpreadModel,
    ProposalAnalyticsApiResponse,
    ProposalAnalyticsModel,
} from "./models/analytics.models";

export class MdmApiService {
    private api: AxiosInstance;

    constructor(
        private serverSide: boolean = false,
        private localRouteForce: boolean = false,
        private authStore?: AuthStore,
        private token?: string,
    ) {
        const baseURL = localRouteForce
            ? `/${baseApiPath}`
            : serverSide
            ? getMdmServiceBasePath
            : getBaseUrl(serverSide);

        this.api = initAxios(baseURL, authStore, token);

        if (authStore) {
            reaction(
                () => authStore?.accessToken,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => !authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );
        }
    }

    getProposalAnalytics(proposalId: number) {
        return this.api
            .get(proposalAnalyticsApiPath(proposalId))
            .then((result: AxiosResponse<ProposalAnalyticsApiResponse>) => result.data?.data as ProposalAnalyticsModel);
    }

    getEventAnalytics(eventId: number): Promise<EventAnalyticsModel | undefined> {
        return this.api
            .get(eventAnalyticsApiPath(eventId))
            .then((result: AxiosResponse<EventAnalyticsApiResponse>) => result.data.data as EventAnalyticsModel);
    }

    getTalkStat(activityId: number): Promise<MdmTalkStatisticModel | undefined> {
        return this.api
            .get(talkStatUrl(activityId))
            .then((result: AxiosResponse<MdmTalkStatisticApiResponse>) => result.data[0] as MdmTalkStatisticModel);
    }

    getTalksStats(eventId: number): Promise<MdmTalkStatisticModel[] | undefined> {
        return this.api
            .get(talksStatsUrl(eventId))
            .then((result: AxiosResponse<MdmTalksStatisticsApiResponse>) => result.data as MdmTalkStatisticModel[]);
    }

    getTagStats(eventId: number): Promise<MdmTagStatisticModel[] | undefined> {
        return this.api
            .get(tagsStatsUrl(eventId))
            .then((result: AxiosResponse<MdmTagsStatisticsApiResponse>) => result.data as MdmTagStatisticModel[]);
    }

    getTalkWatchSpread(activityId: number): Promise<MdmTalkWatchSpreadModel[] | undefined> {
        return this.api
            .get(talkWatchSpreadUrl(activityId))
            .then((result: AxiosResponse<MdmTalkWatchSpreadApiResponse>) => result.data as MdmTalkWatchSpreadModel[]);
    }

    getDiscussionHist(activityId: number): Promise<MdmDiscussionHistModel[] | undefined> {
        return this.api
            .get(discussionHistUrl(activityId))
            .then((result: AxiosResponse<MdmDiscussionHistApiResponse>) => result.data as MdmDiscussionHistModel[]);
    }

    getTalkRecordHist(activityId: number): Promise<MdmTalkHistModel[] | undefined> {
        return this.api
            .get(talkRecordHistUrl(activityId))
            .then((result: AxiosResponse<MdmTalkHistApiResponse>) => result.data as MdmTalkHistModel[]);
    }

    getTalkLiveHist(activityId: number): Promise<MdmTalkHistModel[] | undefined> {
        return this.api
            .get(talkLiveHistUrl(activityId))
            .then((result: AxiosResponse<MdmTalkHistApiResponse>) => result.data as MdmTalkHistModel[]);
    }

    getTalkOffileHist(activityId: number): Promise<MdmTalkHistModel[] | undefined> {
        return this.api
            .get(talkOfflineHistUrl(activityId))
            .then((result: AxiosResponse<MdmTalkHistApiResponse>) => result.data as MdmTalkHistModel[]);
    }

    getTalkRatings(activityId: number): Promise<MdmTalkRatingModel[] | undefined> {
        return this.api
            .get(talkRatingsUrl(activityId))
            .then((result: AxiosResponse<MdmTalkRatingsApiResponse>) => result.data as MdmTalkRatingModel[]);
    }
}
