export const TIME_SLOT_WIDTH = 64;
export const TRACK_MARGIN_LEFT = 8;
export const MIN_TIME_INTERVAL_MINUTES = 5;
export const START_TIME_MINUTES = 0; // 00:00
export const END_TIME_MINUTES = 60 * 24 - MIN_TIME_INTERVAL_MINUTES; // 23:55
export const SCHEDULE_ROW_HEIGHT = 32;
export const TRACK_WIDTH = 250;
export const ACTIVITY_AUTO_SCROLL_FAULT = SCHEDULE_ROW_HEIGHT * 12;
export const EVENT_SELECTOR_WIDTH = 200;
export const FOOTER_HEIGHT = 100;
export const MIN_ACTIVITY_DURATION_MINUTES = 10;
export const MAX_ACTIVITY_DURATION_MINUTES = 720;
export const MIN_MINUTES_TO_SHOW_SPEAKERS = 30;

export const TRACKS_LEFTS = [
    79, // 1
    79 + TRACK_WIDTH + 8, // 2
    79 + TRACK_WIDTH * 2 + 8 * 2, // 3
    79 + TRACK_WIDTH * 3 + 8 * 3, // 4
    79 + TRACK_WIDTH * 4 + 8 * 4, // 5
    79 + TRACK_WIDTH * 5 + 8 * 5, // 6
    79 + TRACK_WIDTH * 6 + 8 * 6, // 7
];

let SCHEDULE_TIMEZONE_OFFSET = -180;

export const setScheduleTimeZone = (timeZone: string) => {
    const date = new Date(new Date().toLocaleString("en-US", { timeZone }));

    SCHEDULE_TIMEZONE_OFFSET = date.getTimezoneOffset();
};

export const getScheduleTimeZoneOffset = () => {
    return -180;
};

export const getTrackLeft = (x: number, tracksCount: number = TRACKS_LEFTS.length): number => {
    let col = Math.trunc(x / TRACK_WIDTH);

    if (col >= tracksCount) {
        col = tracksCount - 1;
    }

    return TRACKS_LEFTS[col];
};

export const getCardTop = (y: number): number => {
    const row = Math.trunc(y / SCHEDULE_ROW_HEIGHT);

    return row * SCHEDULE_ROW_HEIGHT - SCHEDULE_ROW_HEIGHT / 2 + 2;
};

export const getCardHeight = (y: number, top: number): number => {
    const min = MIN_ACTIVITY_DURATION_MINUTES / MIN_TIME_INTERVAL_MINUTES;
    const max = MAX_ACTIVITY_DURATION_MINUTES / MIN_TIME_INTERVAL_MINUTES;

    let rows = Math.trunc((y - top) / SCHEDULE_ROW_HEIGHT);

    if (rows < min) {
        rows = min;
    } else if (rows > max) {
        rows = max;
    }

    return rows * SCHEDULE_ROW_HEIGHT;
};

export const getTrackNumberByLeft = (left: number): number => TRACKS_LEFTS.findIndex((x) => x === left) + 1;

export const getTrackNumberByX = (x: number): number | undefined =>
    x >= TRACKS_LEFTS[0] && x < TRACKS_LEFTS[1]
        ? 1
        : x >= TRACKS_LEFTS[1] && x < TRACKS_LEFTS[2]
        ? 2
        : x >= TRACKS_LEFTS[2] && x < TRACKS_LEFTS[3]
        ? 3
        : x >= TRACKS_LEFTS[3] && x < TRACKS_LEFTS[4]
        ? 4
        : x >= TRACKS_LEFTS[4] && x < TRACKS_LEFTS[5]
        ? 5
        : x >= TRACKS_LEFTS[5] && x < TRACKS_LEFTS[6]
        ? 6
        : x >= TRACKS_LEFTS[6]
        ? 7
        : undefined;

export const getRowByY = (y: number): number => Math.trunc(y / SCHEDULE_ROW_HEIGHT);

export const getMinutesByRow = (row: number): number => row * MIN_TIME_INTERVAL_MINUTES;

export const getNumericSizeByPixel = (pixel: string): number => Math.trunc(+pixel?.replace("px", ""));

export const updateTimeWithMinutes = (date: Date, minutes: number, withOffset = false): Date => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);

    if (withOffset) {
        const offset = getOffsetHoursFactor(date);

        return new Date(newDate.valueOf() + (minutes - offset * 60) * 60 * 1000);
    }

    return new Date(newDate.valueOf() + minutes * 60 * 1000);
};

export const isNewHour = (minutes: number): boolean => minutes % 60 === 0;

export const calculateActivityLeft = (trackNumber: number, trackWidth: number): number => {
    const scrollPanelPadding = 15;

    return scrollPanelPadding + TIME_SLOT_WIDTH + (trackNumber - 1) * 8 + (trackNumber - 1) * trackWidth;
};

export const getOffsetHoursFactor = (date: Date): number => {
    const offset = date.getTimezoneOffset();
    const scheduleTimeZoneOffset = getScheduleTimeZoneOffset();

    return (scheduleTimeZoneOffset - offset) / -60;
};

export const calculateActivityTop = (startDate: Date): number => {
    const timeContainerFault = 18;

    const startDateOffsetHoursFactor = getOffsetHoursFactor(startDate);

    const hours = startDate.getHours() + startDateOffsetHoursFactor;
    const minutes = startDate.getMinutes();

    const row = (hours * 60 + minutes) / MIN_TIME_INTERVAL_MINUTES;

    return timeContainerFault + row * SCHEDULE_ROW_HEIGHT;
};

export const calculateActivityHeight = (startDate: Date, endDate: Date): number => {
    const timeContainerFault = 18;

    const startDateOffsetHoursFactor = getOffsetHoursFactor(startDate);
    const endDateOffsetHoursFactor = getOffsetHoursFactor(endDate);

    const startHours = startDate.getHours() + startDateOffsetHoursFactor;
    const startMinutes = startDate.getMinutes();

    const endHours = endDate.getHours() + endDateOffsetHoursFactor;
    const endMinutes = endDate.getMinutes();

    const startRow = (startHours * 60 + startMinutes) / MIN_TIME_INTERVAL_MINUTES;
    const endRow = (endHours * 60 + endMinutes) / MIN_TIME_INTERVAL_MINUTES;

    return timeContainerFault + endRow * SCHEDULE_ROW_HEIGHT - (timeContainerFault + startRow * SCHEDULE_ROW_HEIGHT);
};

export const calculateScheduleWidth = (tracksCount: number): number => {
    const beautyFactor = 32 + 64;

    return TIME_SLOT_WIDTH + tracksCount * TRACK_WIDTH + tracksCount * TRACK_MARGIN_LEFT + beautyFactor;
};

export const getBeautyHHMMTime = (time: Date): string => {
    const timeArray = time.toTimeString().split(":");

    return `${timeArray[0]}:${timeArray[1]}`;
};

export const withOffsetHoursFactor = (date: Date): Date => {
    const offsetHoursFactor = getOffsetHoursFactor(date);

    return new Date(date.valueOf() + offsetHoursFactor * 60 * 60 * 1000);
};

export const withoutOffsetHoursFactor = (date: Date): Date => {
    const offsetHoursFactor = getOffsetHoursFactor(date);

    return new Date(date.valueOf() - offsetHoursFactor * 60 * 60 * 1000);
};
