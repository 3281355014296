const HOURS = 5;

const analyticsApiPath = "/analytics";
export const proposalAnalyticsApiPath = (proposalId: number) => `${analyticsApiPath}/proposals/${proposalId}`;
export const eventAnalyticsApiPath = (eventId: number) => `${analyticsApiPath}/events/${eventId}`;

export const talkStatUrl = (activityId: number) => `online/talk_id_stats?talk_id=eq.${activityId.toString()}`;
export const talksStatsUrl = (eventId: number) => `online/talk_id_stats?event_id=eq.${eventId.toString()}`;
export const tagsStatsUrl = (eventId: number) => `online/talk_id_stats_by_tags?event_id=eq.${eventId.toString()}`;
export const talkWatchSpreadUrl = (activityId: number) =>
    `online/talk_id_watch_spread?talk_id=eq.${activityId.toString()}&order=watching_deep`;
export const discussionHistUrl = (activityId: number) =>
    `online/online_discussion_histogram?talk_id=eq.${activityId.toString()}&order=offset_minutes&limit=${HOURS * 60}`;
export const talkRecordHistUrl = (activityId: number) =>
    `online/talk_id_record_histogram?talk_id=eq.${activityId.toString()}&order=player_offset_min&limit=${HOURS * 60}`;
export const talkLiveHistUrl = (activityId: number) =>
    `online/talk_id_live_histogram?talk_id=eq.${activityId.toString()}&order=player_offset_min&limit=${HOURS * 60}`;
export const talkOfflineHistUrl = (activityId: number) =>
    `online/talk_id_offline_histogram?talk_id=eq.${activityId.toString()}&order=player_offset_min&limit=${HOURS * 60}`;
export const talkRatingsUrl = (activityId: number) =>
    `online/talk_id_ratings?talk_id=eq.${activityId.toString()}&order=form_rating.desc`;
